import * as React from "react";
import { useAuth0 } from '../../hooks/use-auth0';

const LoginPage = () => {
    const { authProvider } = useAuth0();
    React.useEffect(() => {
        async function login() {
            await authProvider.login();
        };
        login();
    }, [authProvider]);
    
    return (<>
        <h1>Redirecting to Login</h1>
    </>)
};

export default LoginPage;

